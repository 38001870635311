$(function() {
    $('.notice__item a').on('click', function(e) {
        if (e.target.hash === '#newsletter-popup') {
            e.preventDefault();
            var newsletterPopup = $('.popup--newsletter');

            if (newsletterPopup.length) {
                newsletterPopup.trigger('newsletter.popup');
            }
        }
    })
});
